import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { SharelockCommonModule } from '../../modules/sharelock-common.module';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'shrl-icon',
  standalone: true,
  imports: [SharelockCommonModule, MatIconModule],
  templateUrl: './icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  @Input({ required: true }) iconName = '';
}
